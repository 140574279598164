//import logo from './logo.svg';
//import React from 'react';
import './App.css';
import {useCallback, useEffect, useState} from "react";
const tg = window.Telegram.WebApp;

function App() {
    /*
    const onSendData = useCallback(()=>{
        const data = {
            orders: order,
            totalPrice: sum
        }
        tg.sendData("olololololololololo");
    });

     */
    const pillsVar = [
        {
            id: 1,
            name: 'Подушка bonfire',
            img: "./bonfire.png",
            price: 1600,
            count: 0
        },
        {
            id: 2,
            name: 'Подушка ocean',
            img: "./ocean.png",
            price: 1600,
            count: 0
        },
        {
            id: 3,
            name: 'Подушка forest',
            img: "./forest.png",
            price: 1600,
            count: 0
        },
        {
            id: 4,
            name: 'Подушка rain',
            img: "./rain.png",
            price: 1600,
            count: 0
        }
    ];
    const [order,setOrder] = useState([]);
    const [sum,setSum] = useState(0);
    const [pills,setPills] = useState(pillsVar);


  useEffect(()=>{
tg.ready();
  },[])

    useEffect(()=>{
        tg.onEvent('MainButtonClicked', function(){
            tg.sendData("some string that we need to send");
            //при клике на основную кнопку отправляем данные в строковом виде
        });
        /*
        return()=>{
            tg.offEvent('MainButtonClicked', onSendData);
        }

         */
    },[])



  async function handleMinus(id,count,price){
if(count > 0){
    count--;
    for (let i=0;i<order.length;i++){
        if(order[i].id === id){
            if(count === 0){
                order.splice(i,1);
            } else {
                order[i].count = count;
            }
            setOrder(order);
            break;
        }
    }
    for (let i=0;i<pills.length;i++){
        if(pills[i].id === id){
            pills[i].count = count;
            setPills(pills);
            break;
        }
    }
    let sumlet = sum-price;
    setSum(sumlet);
    console.log(order);
    if (sumlet === 0){
        tg.MainButton.hide();
    } else {
        tg.MainButton.show();
        tg.MainButton.setParams({text : `Оформить ${sumlet} грн`})
    }
}
  }

  async function handlePlus(id,count,price){
      let checker = false;
      count++;
      for (let i=0;i<order.length;i++){
          if(order[i].id === id){
              order[i].count = count;
              setOrder(order);
              checker = true;
              break;
          }
      }
      for (let i=0;i<pills.length;i++){
          if(pills[i].id === id){
              pills[i].count = count;
              setPills(pills);
              if(!checker){
                  order.push(pills[i])
                  setOrder(order);
              }
              break;
          }
      }
      let sumlet = sum+price;
      setSum(sumlet);
console.log(order);

      tg.MainButton.show();
      tg.MainButton.setParams({text : `Оформить ${sumlet} грн`})
  }









  return (
    <div className="App">
        <div className="container">
        {pills.map((pills) =>{
            return <div className="main">
                { pills.count > 0 ? <div className="count">{pills.count}</div>:<div className="count" style={{visibility: "hidden"}}></div>}
                <div className="img"><img alt='pill' src={pills.img} className="imgpills"/></div>
                <span className="span">{pills.name}</span>
                <span className="spanPrice">{pills.price} UAH</span>
                <div className="buttons">
                    <div className="minus" onClick={()=>handleMinus(pills.id,pills.count,pills.price)}><img alt='minus' src={'./minus.png'} className="imgM"/></div>
                    <div className="plus" onClick={()=> handlePlus(pills.id,pills.count,pills.price)}><img alt='plus' src={'./plus.png'} className="imgP"/></div>
                </div>
            </div>}
        )}</div>
    </div>
  );
}

export default App;
